/**
 *
 * LanguageButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediumBlue } from '../../global-styles';

const StyledLanuageButton = styled.button`
  width: 80px;
  height: 65px;
  margin: 10px;
  padding: 8px 0 9px 0;
  border-radius: 4px;
  box-shadow: ${(props) =>
    props.checked
      ? '0 0 10px 0 rgba(99, 140, 177, 0.2)'
      : '0 0 10px 0 rgba(99, 140, 177, 0.1)'};
  border: ${(props) =>
    props.checked
      ? `solid 2px ${mediumBlue}`
      : 'solid 1px rgba(46, 95, 202, 0.1)'};
  background-color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  .lb-label {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #0f2045;
  }
  &:enabled {
    &:focus,
    &:hover {
      box-shadow: 0 0 10px 0 rgba(99, 150, 177, 0.4);
      outline: none;
    }
  }
`;

/* eslint-disable react/prefer-stateless-function */
class LanguageButton extends React.PureComponent {
  render() {
    const { language, checked, handleClick, disabled } = this.props;

    return (
      <StyledLanuageButton
        type="button"
        checked={checked}
        disabled={disabled}
        onClick={() => handleClick(language.value, checked)}
      >
        <div className="lb-label">{language.label}</div>
      </StyledLanuageButton>
    );
  }
}

LanguageButton.propTypes = {
  language: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

LanguageButton.defaultProps = {
  handleClick: () => {},
  checked: false,
  disabled: false,
};

export default LanguageButton;
